import store from "@/store/index.js";
import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import { _ } from "vue-underscore";
import assignModal from "../../manage/lead-assign.vue";
import feedbackModal from "../../manage/lead-feedback.vue";
import phoneCallModal from "../phoneCall-modal.vue";
import SmsModal from "../SMS-modal.vue";
import emailModal from "../email-modal.vue";
import DatePicker from "vue2-datepicker";
import leadCommentFeedbackModal from "../leadFeedbackComments-modal.vue";
import "vue2-datepicker/index.css";
import leadSearch from "../../../sales/list/saleRefSearch.vue";
import PaginationComponent from "../../../../Global-Component/pagination.vue";
import ChatComponent from "../../../../Global-Component/crm-chat-popup/crm-chat.vue";
export default {
  data: () => ({
    lead: {
      lead_id: "",
      lead_number: "",
      rate_request: "",
    },
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
    showPhoneCall: false,
    selectedClient: {},
    showSmsCall: false,
    showEmails: false,
    lead_feedback_comment: [],
    comments: [],
    newComment: "",
    leadList: [],
    users: [],
    quotations: [],
    commentList: [],
    showLoader: false,
    searchText: "",
    modalOpen: false,
    selectedLead: null,
    selectedRating: null,
    selectedLeadNumber: "",
    selectedLeadSelfLeadAttribute: false,
    showleadCommentFeedBack: false,
    selectedLeadId: [],
    selectedLeadRating: "",
    currentComponent: null,
    newComment: "",
    showButton: false,
    selectedRows: [],
    selectedLeadIds: [],
    filteredData: "",
    teamList: [],
    agentList: [],
    phoneButton: true,
    emailButton: true,
    smsButton: true,
    ActionButton: false,
    selectedTeam: "",
    selectedAgent: "",
    selectedReason: {
      queue: "",
      reason: "",
    },
    all_leads: localStorage.getItem("all_leads") === "true" || false,
    datePickerFormat: "YYYY-MMM-DD",
    leadDateFrom: "",
    leadDateTo: "",
    searchLeadName: "",
    filterData: false,
    clientLastname: "",
    clientFirstname: "",
    reply: [],
    ClearSearch: false,
    leadSearch: false,
    selectedSearchInput: {
      agent: true,
      lead_id: true,
      client_name: true,
      client_email: true,
      client_phone: true,
      from: true,
      to: true,
    },
    lastQueryString: "",
    companies: [],
  }),
  mixins: [paginationMixin],
  components: {
    "header-list": headerList,
    "lead-assign": assignModal,
    "lead-feedback": feedbackModal,
    "phoneCall-modal": phoneCallModal,
    "SMS-modal": SmsModal,
    "email-modal": emailModal,
    "date-picker": DatePicker,
    "lead-CommentFeedBack": leadCommentFeedbackModal,
    "lead-search": leadSearch,
    PaginationComponent,
    ChatComponent,
  },
  watch: {
    all_leads(newValue, oldVal) {
      localStorage.setItem("all_leads", newValue);
      if (newValue !== oldVal) {
        this.totalLeadUnquoted = 0;
        this.totalLeadsActive = 0;
        this.totalLeads = 0;
        this.totalLeadNoPqSent = 0;
        this.totalLeadsSold = 0;
        this.totalLeadFeedback = 0;
        this.totalLeadAskExpert = 0;
        this.totalLeadTaken = 0;
        this.totalLeadQuoted = 0;
      }
      this.getAll();
    },
    leadDateFrom(newVal) {
      if (!newVal) {
        this.leadDateTo = "";
      }
    },
  },
  computed: {
    selectedLeadList() {
      if (this.leadList.length > 0) {
        return _.where(this.leadList, { isSelected: true });
      }
      return [];
    },
    isCreateActionPermission() {
      const permission = this.hasCreateActionPermission();
      return permission;
    },
    isApproveAndRejectPermission() {
      const permission = this.hasApproveAndRejectPermission();
      return permission;
    },
    selectAllList() {
      if (this.leadList.length > 0) {
        return (
          _.where(this.leadList, { isSelected: true }).length ===
          this.leadList.length
        );
      }
      return false;
    },
    isActionHidePermission() {
      const permission = this.hasActionHidePermission();
      return permission;
    },
    isLeadDateToDisabled() {
      return this.leadDateFrom === "" || this.leadDateFrom === null;
    },
    isCloseOtherLeadPermission() {
      return this.hasCloseOtherLeadPermission();
    },
    isToggleSwitchPermission() {
      const permission = this.hasToggleSwitchPermission();
      return permission;
    },
    isleadsFeedBackPermission() {
      const permission = this.hasleadsFeedBackPermission();
      return permission;
    },
  },
  methods: {
    initializeCompanies() {
      const companiesString = process.env.VUE_APP_TRANSFERS_TO_COMPANIES;
      if (companiesString) {
        this.companies = companiesString.split(",").map((slug) => {
          const name = slug
            .replace(/_/g, " ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          return {
            name: name,
            slug: slug,
          };
        });
      }
    },
    formatQueueName(key) {
      switch (key) {
        case "Unassigned":
          return "Unassigned";
        case "No_PQ_Sent":
          return "No Quote Sent";
        case "Active":
          return "Active";
        case "Recently_Sold":
          return "Recently Sold";
        case "Feedback":
          return "Feedback Requested";
        case "Approval_Pending":
          return "Pending Approvals";
        case "Referral_Approval_Pending":
          return "Referral Pending Approvals";
        case "Transfer_Approval_Pending":
          return "Transfer Pending Approvals";
        case "Contact_Deletion_Request":
          return "Contact Deletion Requests";
        case "Ask_Expert":
          return "Quote Requested";
        case "Sold":
          return "Sold";
        case "Unquoted":
          return "Expert Unassigned";
        case "Taken":
          return "Expert Active";
        case "Quoted":
          return "Expert Quoted";
        case "Bonus":
          return "Bonus";
        case "Closed":
          return "Closed";
        case "Expired":
          return "Expired";
        case "Follow_Up_Done":
          return "Follow Up Done";
        case "Client_Not_Ready":
          return "Client Not Ready";
        case "Wrong_Contact_Info":
          return "Wrong Contact Info";
        case "Duplicate":
          return "Duplicate";
      }
    },
    // formatDateTime(timestamp) {
    //   const date = new Date(timestamp);
    //   const months = [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ];

    //   const month = months[date.getMonth()];
    //   const day = date.getDate().toString().padStart(2, "0");
    //   const year = date.getFullYear().toString().slice(2);
    //   const time = timestamp.slice(11, 16);

    //   return `${month}/${day}/${year} ${time}`;
    // },
    hasToggleSwitchPermission() {
      const statusMap = {
        Bonus: "other-leads-bonus-view-all-records",
        Closed: "other-leads-closed-view-all-records",
        Unassigned: "leads-unassigned-view-all-records",
        No_PQ_Sent: "leads-no-pq-sent-view-all-records",
        Active: "leads-active-view-all-records",
        Sold: "leads-sold-view-all-records",
        Feedback: "leads-feedback-view-all-records",
        Ask_Expert: "leads-ask-expert-view-all-records",
        Unquoted: "leads-unquoted-view-all-records",
        Taken: "leads-taken-view-all-records",
        Quoted: "leads-quoted-view-all-records",
      };

      const desiredData = statusMap[this.$route.params.status];
      const hasPermission =
        desiredData && this.$store.state.slugsList.includes(desiredData);
      return hasPermission;
    },

    hasCloseOtherLeadPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-close-other";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasleadsFeedBackPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-feedback";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },

    hasApproveAndRejectPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-close-approve-reject";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },

    hasActionHidePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = ["leads-assign", "leads-feedback"];

      let hasPermission = desiredData.some((item) => slugsList.includes(item));
      return hasPermission;
    },

    hasCreateActionPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-assign";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },

    assignUser(id) {
      let ids = [];
      if (id > 0) {
        ids = [id];
      } else {
        this.leadList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      this.selectedLeadIds = ids;
      this.currentComponent = "lead-assign";
    },
    updateAssignment() {
      this.currentComponent = null;
      this.getAll(false);
    },
    phoneCall(lead, leadNumber) {
      let _vm = this;
      let clientId = lead.client_id;
      _vm.getDetail(clientId);
      _vm.selectedLeadNumber = leadNumber;
      _vm.selectedLeadId = [lead.id];
      _vm.selectedLead = lead;
      _vm.showPhoneCall = true;
    },
    sms(lead, leadNumber) {
      let _vm = this;
      let clientId = lead.client_id;
      _vm.getDetail(clientId);
      _vm.selectedLeadNumber = leadNumber;
      _vm.selectedLeadId = [lead.id];
      _vm.selectedLead = lead;
      _vm.showSmsCall = true;
    },

    email(lead, leadNumber) {
      let _vm = this;
      let clientId = lead.client_id;
      _vm.getDetail(clientId);
      _vm.selectedLeadNumber = leadNumber;
      _vm.selectedLeadId = [lead.id];
      _vm.selectedLead = lead;
      _vm.showEmails = true;
    },

    leadCommentFeedbackInfo(lead, leadNumber) {
      let _vm = this;
      let clientId = lead.client_id;
      _vm.getDetail(clientId);
      _vm.selectedLeadNumber = leadNumber;
      _vm.selectedLead = lead;
      _vm.showleadCommentFeedBack = true;
    },
    updateFeedback() {
      this.currentComponent = "";
      this.getAll(false);
    },
    handleSelection(event) {
      const selectedIndex = event.target.selectedIndex;
      const selectedOption = event.target.options[selectedIndex];
      const selectedOptionValue = selectedOption.value;
      let Queue = "";

      switch (selectedOptionValue) {
        case "Move to bonus queue":
          Queue = "Bonus";
          break;
        case "Duplicate lead":
          Queue = "Duplicate";
          break;
        case "Contact info is not correct":
          Queue = "Wrong_Contact_Info";
          break;
        case "Not reachable -Followup done":
          Queue = "Follow_Up_Done";
          break;
        case "Client is not ready yet":
          Queue = "Client_Not_Ready";
          break;
        case "Travel date past":
          Queue = "Expired";
          break;
        case "Trip cancelled":
        case "Booked Somewhere else":
        case "Another agent working with client":
        case "Other specific reason":
          Queue = "Closed";
          break;
      }

      this.selectedReason.queue = Queue;
      this.selectedReason.reason = selectedOptionValue;
    },
    // getNextPage(page) {
    //   this.$refs.paginate.selected = page;
    //   this.paginate.current_page = page;
    //   if (
    //     this.leadDateFrom !== "" ||
    //     this.searchLeadName !== "" ||
    //     this.leadDateTo !== "" ||
    //     this.selectedAgent !== ""
    //   ) {
    //     this.submitSearchLead();
    //   } else {
    //     this.getAll();
    //   }
    // },
    getBoth() {
      if (
        this.leadDateFrom !== "" ||
        this.searchLeadName !== "" ||
        this.leadDateTo !== "" ||
        this.selectedAgent !== ""
      ) {
        this.submitSearchLead();
      } else {
        this.getAll();
      }
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      if (
        this.leadDateFrom !== "" ||
        this.searchLeadName !== "" ||
        this.leadDateTo !== "" ||
        this.selectedAgent !== ""
      ) {
        this.submitSearchLead();
      } else {
        this.getAll();
      }
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getBoth();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    getQueryString(selectedData) {
      let queryString = "?all_leads=" + this.all_leads;
      queryString +=
        (queryString.includes("?") ? "&" : "") +
        "status=" +
        this.$route.params.status;
      queryString += this.paginate.current_page
        ? "&page=" + this.paginate.current_page
        : "";
      queryString += this.paginate.per_page
        ? "&take=" + this.paginate.per_page
        : "";
      queryString += selectedData.agent_id
        ? "&agent=" + selectedData.agent_id
        : "";
      queryString += selectedData.Lead ? "&lead_id=" + selectedData.Lead : "";
      queryString += selectedData.Client_name
        ? "&client_name=" + selectedData.Client_name
        : "";
      queryString += selectedData.client_email
        ? "&client_email=" + selectedData.client_email
        : "";
      queryString += selectedData.client_phone
        ? "&client_phone=" + selectedData.client_phone
        : "";
      queryString += selectedData.saleDateFrom
        ? "&from=" + selectedData.saleDateFrom
        : "";
      queryString += selectedData.saleDateTo
        ? "&to=" + selectedData.saleDateTo
        : "";
      return queryString;
    },

    getAll(selectedData = {}) {
      let _vm = this;
      store.state.isLoaderShow = true;
      if (Object.keys(selectedData).length === 0 && this.lastQueryString) {
        selectedData = this.lastQueryString;
      } else {
        this.lastQueryString = selectedData;
      }

      let queryString = this.getQueryString(selectedData);
      _vm.axios
        .get("/leads" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let data = JSON.parse(atob(response.data.data));
          _vm.paginate.last_page = data.last_page;
          _vm.leadList = data.data;
          _vm.paginate.page_count = _vm.getPageCount(data.total, data.per_page);
        })
        .catch(function (error) {
          // Handle error
        });
    },
    setAssign(event, leadId) {
      let _vm = this;
      _vm.$nextTick(() => {
        _vm.axios
          .post(
            `/assign-user?lead_id=${leadId}` + "&user_id=" + event.target.value
          )
          .then(function () {
            _vm.getAll();
          })
          .catch(function (error) {});
      });
    },
    getDetail(clientId) {
      let _vm = this;
      this.axios
        .get("/client-by-lead/" + clientId)
        .then(function (response) {
          _vm.selectedClient = JSON.parse(atob(response.data.data));
        })
        .catch(function (error) {
          // Handle error
        });
    },
    handleCheck(e) {
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          this.selectedRows = this.leadList.map((item) => "checkbox" + item.id);
          this.leadList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          this.selectedRows = [];
          this.leadList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          this.selectedRows.push(e.target.id);
        } else {
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
    getTeamDetail() {
      const _vm = this;
      this.axios
        .get("/team-details", _vm.teamList)
        .then(function (response) {
          _vm.teamList = response.data.data;
        })
        .catch(function () {});
    },
    getTeamWiseAgentDetail() {
      const _vm = this;
      this.axios
        .get("/team-wise-user", _vm.agentList)
        .then(function (response) {
          _vm.agentList = response.data.data;
        })
        .catch(function () {});
    },
    // for popup search
    openModal() {
      this.leadSearch = true;
    },
    handleSearch(selectedData) {
      let _vm = this;
      _vm.getAll(selectedData);
      _vm.leadSearch = false;
      _vm.ClearSearch = true;
    },
    isWithinNext14Months(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      return date > today;
    },
    clearAll() {
      let _vm = this;
      _vm.ClearSearch = false;
      _vm.lastQueryString = "";
      store.state.isLoaderShow = true;
      _vm.getAll();
    },
  },
  mounted() {
    this.getTeamDetail();
    this.initializeCompanies();
    this.getTeamWiseAgentDetail();
    this.getAll();
  },
};
